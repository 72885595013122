<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center align-content-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
      <h4 class="m-0 pl-1">
        <a
          :href="`https://${systemURL}`"
          target="_blank"
        >{{ systemURL }}
          <feather-icon
            icon="ExternalLinkIcon"
            size="15"
          /></a>
      </h4>
      <b-badge
        variant="light-primary"
        class="ml-2"
      >
        URL de indicação
      </b-badge>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-badge
        v-if="!getStatusCompany"
        id="badge-bloqueio"
        variant="danger"
        class="ml-2"
      >
        <feather-icon
          icon="AlertCircleIcon"
          class="mr-1"
        />
        <span>Plano Bloqueado</span>
        <b-tooltip
          triggers="hover"
          target="badge-bloqueio"
          title="Não fique sem indicações, ative seu plano acessando o menu Plano e pagamento"
          :delay="{ show: 50, hide: 50 }"
        />
      </b-badge>
      <!-- <locale /> -->
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BBadge, BTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import store from '@/store'
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
import useJwt from '@/auth/jwt/useJwt'
import jwtDecode from 'jwt-decode'
import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BBadge,
    BTooltip,

    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    // Locale,
    // SearchBar,
    DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getStatusCompany: 'company/getStatusCompany',
    }),
    systemURL() {
      return jwtDecode(window.localStorage.getItem('accessToken')).SystemUrl
    },
  },
  created() {
    this.getActualPlan()
  },
  methods: {
    getActualPlan() {
      useJwt.getActivePlan()
        .then(response => {
          const companyStatus = response.data.result.activeCompany
          return store.commit('company/TOGGLE_STATUS_COMPANY', companyStatus)
        })
    },
  },
}
</script>
