<template>
  <b-modal
    id="modalConfigCompany"
    v-model="activeConfigCompanyModal"
    hide-footer
    centered
  >
    <b-row class="m-0">
      <b-col
        cols="12"
        class="d-flex flex-column p-2 bg-primary"
      >
        <b-img
          :src="require('@/assets/images/logo/logo-indiquei-light.png')"
          class="mr-1"
          width="140"
          alt="Indiquei.vc"
        />
      </b-col>
    </b-row>
    <b-overlay
      :show="isAPICallInProgress"
      rounded="sm"
    >
      <form-wizard
        ref="formWizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        finish-button-text="Finalizar Configurações"
        next-button-text="Avançar"
        class="steps-transparent"
        @on-complete="formSubmitted"
      >
        <tab-content
          title=""
          icon="feather icon-shopping-bag"
        >
          <b-row>
            <b-col
              cols="12"
              class="d-flex flex-column align-items-center justify-content-center p-2 bg-primary"
            >
              <feather-icon
                icon="SmileIcon"
                size="45"
                class="m-2 text-light"
              />
              <h3 class="text-light">
                Seja Bem-vindo!
              </h3>
            </b-col>
            <b-col
              cols="12"
              class="px-3 pt-2 d-flex flex-column align-items-center justify-content-center text-center"
            >
              <h5>Antes de iniciar seu acesso, precisamos personalizar suas informações. Vamos lá?</h5>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content
          title="Etapa02"
          icon="feather icon-shopping-bag"
          :before-change="validateStep2"
        >
          <validation-observer ref="step-2">
            <b-row>
              <b-col
                cols="12"
                class="py-2 px-4"
              >
                <h5>
                  Como gostaria de apresentar sua empresa no indiquei.vc?
                </h5>
              </b-col>
              <b-col
                cols="12"
                class="mt-1 px-4 d-flex flex-column"
              >
                <b-form-group
                  label="Nome fantasia"
                  label-for="fantasyName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nome da fantasia"
                    rules="required"
                  >
                    <b-form-input
                      v-model="companyData.fantasyName"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="Nome da empresa"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="d-flex align-items-center px-4 my-1">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="12"
                  class="mr-1"
                />
                <small> Esse será o nome que aparecerá para todos os seus indicados.</small>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title="Etapa03"
          icon="feather icon-shopping-bag"
          :before-change="validateStep3"
        >
          <validation-observer ref="step-3">
            <b-row>
              <b-col
                cols="12"
                class="py-2 px-4"
              >
                <h5>
                  Agora, {{ companyData.fantasyName }}, faça o upload de seu logotipo e defina as cores do seu aplicativo.
                </h5>
              </b-col>
              <b-col
                cols="6"
                class="mt-1 px-4 d-flex flex-column"
              >
                <div class="containerLogo">
                  <b-img
                    v-if="!logoPreview"
                    :src="companyData.urlLogo"
                    class="mb-1"
                    alt="svg img"
                  />
                  <b-img
                    v-else
                    :src="logoPreview"
                    alt="svg img"
                    class="mb-1"
                  />
                </div>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  :disabled="$can('edit', 'settingBusiness') === false"
                  @click="$refs.logoInput.click()"
                >
                  Editar logo
                </b-button>

                <input
                  ref="logoInput"
                  type="file"
                  accept="image/*"
                  style="display:none"
                  @change="onLogoSelected"
                >
              </b-col>
              <b-col
                cols="6"
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <div class="inputColorGroup">
                  <input
                    v-model="companyData.primaryColor"
                    type="color"
                    class="mr-1"
                    :disabled="$can('edit', 'settingBusiness') === false"
                  >
                  <span>
                    <b-form-group
                      label="Cor primária"
                      label-for="primaryColor"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="cor primária"
                        rules="required"
                      >
                        <b-form-input
                          v-model="companyData.primaryColor"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          size="md"
                          placeholder="#000000"
                          :disabled="$can('edit', 'settingBusiness') === false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </span>
                </div>
                <!--  -->
                <div class="inputColorGroup">
                  <input
                    v-model="companyData.secondaryColor"
                    type="color"
                    class="mr-1"
                    :disabled="$can('edit', 'settingBusiness') === false"
                  >
                  <span>
                    <b-form-group
                      label="Cor secundária"
                      label-for="secondaryColor"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="cor secundária"
                        rules="required"
                      >
                        <b-form-input
                          v-model="companyData.secondaryColor"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          size="md"
                          placeholder="#000000"
                          :disabled="$can('edit', 'settingBusiness') === false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </span>
                </div>
              </b-col>
              <b-col class="d-flex align-items-center px-4 my-1">
                <feather-icon
                  icon="AlertCircleIcon"
                  size="12"
                  class="mr-1"
                />
                <small> Dê preferência a imagens com fundo transparente e com boa qualidade. Formatos permitidos: PNG, EPS, SVG e JPG. </small>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </b-modal>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'

import {
  BRow, BCol, BModal, BFormInput, BFormGroup, BImg, BButton, BOverlay,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

import { FormWizard, TabContent } from 'vue-form-wizard'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import generateSwatchesColorsBrand from '@/utils/generateSwatchesColorsBrand'

export default {
  name: 'ModalConfigCompany',
  components: {
    BRow,
    BCol,
    BModal,
    FormWizard,
    TabContent,
    BFormInput,
    BFormGroup,
    BImg,
    BButton,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    activeConfigCompanyModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // fantasyName: '',
      required,
      logoPreview: null,
      selectedLogo: null,
      newLogoBase64: '',
      extNewLogo: '',
      companyData: {
        urlLogo: '',
        primaryColor: '',
        secondaryColor: '',
        fantasyName: '',
      },
    }
  },
  mounted() {
    localize('pt_BR')
    this.getCompanyInfo()
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  methods: {
    getCompanyInfo() {
      useJwt.getCompanyInfo()
        .then(response => {
          this.companyData = {
            ...response.data.result,
          }
          return response
        })
    },
    closeModal() {
      store.commit('api/TOGGLE_MODAL_CONFIG_COMPANY', false)
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$refs['step-2'].validate()
          .then(success => {
            if (success) {
              return resolve(true)
            }
            return resolve(false)
          })
          .catch(error => reject(error))
      })
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$refs['step-3'].validate()
          .then(success => {
            if (success) {
              return resolve(true)
            }
            return resolve(false)
          })
          .catch(error => reject(error))
      })
    },
    formSubmitted() {
      const cores = generateSwatchesColorsBrand(
        this.companyData.primaryColor,
        this.companyData.secondaryColor,
      )

      useJwt.updateInfoCompany({
        fantasyName: this.companyData.fantasyName,
        primaryColor: this.companyData.primaryColor,
        secondaryColor: this.companyData.secondaryColor,
      })
        .then(() => {
          useJwt.updateColorsCompany({
            ...cores,
          })
            .then(() => {
              if (this.newLogoBase64) {
                useJwt.updateLogoCompany({
                  logo: this.newLogoBase64,
                  extension: this.extNewLogo,
                })
                  .then(() => {
                    this.closeModal()
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Configurações da empresa atualizadas!',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(error => error)
              } else {
                this.closeModal()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Configurações da empresa atualizadas!',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
              }
            })
            .catch(error => error)
        })
        .catch(error => error)
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onLogoSelected(event) {
      const newLogo = event.target.files[0]
      const spliteredName = newLogo.name.split('').reverse()

      this.extNewLogo = `${spliteredName[3]}${spliteredName[2]}${spliteredName[1]}${spliteredName[0]}`

      try {
        const result = await this.toBase64(newLogo)
        // eslint-disable-next-line prefer-destructuring
        this.newLogoBase64 = result.split(',')[1]
      } catch (error) {
        // console.log('error: ', error)
      }

      this.logoPreview = URL.createObjectURL(newLogo)
      this.selectedLogo = newLogo
    },
  },
}
</script>

<style lang="scss" scope>
  @import '@core/scss/vue/libs/vue-wizard.scss';

#modalConfigCompany {
  // reset do modal
  .modal-header {
    display: none !important;
  }

  .modal-body {
    padding: 0 !important;
  }

  // reset do form wizard
  .vue-form-wizard .wizard-tab-content {
    padding: 0 14px 14px 14px;
  }

  ul.wizard-nav.wizard-nav-pills {
    display: none !important;
  }

  .wizard-card-footer .wizard-footer-left {
    display: none !important;
  }

  .inputColorGroup {

      display: flex !important;
      flex-direction: row-reverse !important;
      align-items: center;

    input[type="color"] {
        appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      border: 0;
      cursor: pointer;
      height: 40px;
      padding: 0;
      width: 40px;
      margin-left: 10px;
    }

    ::-webkit-color-swatch{
      border: 0;
      border-radius: 999px;
    }

    ::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    ::-moz-color-swatch,
    ::-moz-focus-inner{
      border: 0;
    }

    ::-moz-focus-inner{
      padding: 0;
    }

    span {
      display: inline-flex;
      flex-direction: column;
      max-width: 90px;

      input {
          max-width: 90px;
        }
  }
}

  .containerLogo{
    display: flex;
    flex-direction: column;
  }
}
</style>
